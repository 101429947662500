@use '../../abstracts/' as a;

.p-recommend {
	background-image: url('/assets/images/pages/index/bg_grid.jpg');
	background-size: 100%;
	background-repeat: repeat-y;
}

.p-recommend__container {
	padding: calc(89 / 750 * 100%) calc(70 / 750 * 100%) calc(43 / 750 * 100%);
}

.p-recommend__heading {
	margin: 0 auto;
	width: calc(499 / 610 * 100%);
}

.p-recommend__list {
	margin-top: calc(33 / 610 * 100%);
}

.p-recommend__bnr {
	display: block;
	position: relative;
	margin-bottom: calc(37 / 610 * 100%);
	border-radius: 10px;
	overflow: hidden;

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&::before {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		border: 2px solid var(--color-blue);
		border-radius: 10px;
		width: 100%;
		height: 100%;
		pointer-events: none;
		content: '';
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-recommend__bnrs {
	display: flex;
	justify-content: space-between;

	.p-recommend__bnr {
		border-radius: 5px;
		width: calc(290 / 610 * 100%);

		&::before {
			border-radius: 5px;
		}
	}
}
