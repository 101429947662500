@use '../../abstracts/' as a;

.p-mv {
	background-image: url('/assets/images/pages/index/bg_blue.jpg');
	background-size: 100%;
	background-repeat: repeat-y;
	padding: calc(19 / 750 * 100%) 0 0;
	overflow: hidden;
}

.p-mv__bodies {
	display: flex;
	width: 200%;
}

.p-mv__body {
	flex-shrink: 0;
	animation: loop 50s -25s linear infinite;
	padding: 0 calc(24.5 / 1500 * 100%);
	width: 50%;

	&:nth-child(2) {
		animation: loop2 50s linear infinite;
	}
}

.p-mv__heading {
	margin: calc(62 / 750 * 100%) auto 0;
	width: calc(606 / 750 * 100%);
}

.p-mv__img {
	margin-top: calc(30 / 750 * 100%);
}

@keyframes loop {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes loop2 {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-200%);
	}
}
