.p-about {
  position: relative;
  z-index: 1;
}
.p-about::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  margin-top: -2.4%;
  background-image: url("/assets/images/pages/index/about_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  content: "";
}

.p-about__container {
  padding: 7.6% 0 0;
}

.p-about__heading {
  margin: 0 auto;
  width: 71.0666666667%;
}

.p-about__body {
  margin-top: 2.6666666667%;
  color: #fff;
  font-size: 1.35rem;
  line-height: 1.5185185185;
  letter-spacing: 0.09em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-about__body {
    font-size: 2.3rem;
  }
}

.p-about__bg {
  position: relative;
  margin-top: 4%;
}

.p-about__btns {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: space-between;
  z-index: 1;
  padding: 4.8% 9.3333333333%;
}
.p-about__btns::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  content: "";
}

.p-about__btn {
  display: block;
  width: 47.3770491803%;
}
@media (min-width: 48em) {
  .p-about__btn {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-about__btn:hover {
    opacity: 0.7;
  }
}

.p-bg {
  background-position: top center;
  background-size: 100%;
  background-repeat: repeat-y;
}

.p-bg--grid {
  background-image: url("/assets/images/pages/index/bg_grid.jpg");
}

.p-department {
  background-image: url("/assets/images/pages/index/bg_grid.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
}

.p-department__container {
  padding: 12.8% 9.3333333333% 8.2666666667%;
}

.p-department__heading {
  margin: 0 auto;
  width: 58.6885245902%;
}

.p-department__body {
  margin-top: 2.4590163934%;
  font-size: 1.35rem;
  line-height: 1.5185185185;
  letter-spacing: -0.05em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-department__body {
    font-size: 2.3rem;
  }
}

.p-department__btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4.5901639344%;
}

.p-department__btn {
  display: block;
  margin-bottom: 4.5901639344%;
  width: 46.393442623%;
}
@media (min-width: 48em) {
  .p-department__btn {
    transition: opacity 0.3s;
  }
}
.p-department__btn:first-child, .p-department__btn:nth-child(6) {
  width: 100%;
}
@media (min-width: 48em) {
  .p-department__btn:hover {
    opacity: 0.7;
  }
}

.p-exam__container {
  padding: 10.1333333333% 9.3333333333% 5.3333333333%;
}

.p-exam__heading {
  margin: 0 auto;
  width: 60.4918032787%;
}

.p-exam__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5.5737704918%;
}

.p-exam__btn {
  display: block;
  margin-bottom: 5%;
  width: 48%;
}
@media (min-width: 48em) {
  .p-exam__btn {
    transition: opacity 0.3s;
  }
}
.p-exam__btn:first-child {
  width: 100%;
}
@media (min-width: 48em) {
  .p-exam__btn:hover {
    opacity: 0.7;
  }
}

.p-graduate__container {
  padding: 8.4% 8% 7.7333333333%;
}

.p-graduate__heading {
  margin: 0 auto;
  width: 79.2063492063%;
}

.p-graduate__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6.1643835616%;
}

.p-graduate__item {
  display: block;
  position: relative;
  margin-bottom: 2.2222222222%;
  border-radius: 5px;
  width: 48.8888888889%;
  overflow: hidden;
}
@media (min-width: 48em) {
  .p-graduate__item {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-graduate__item:hover {
    opacity: 0.7;
  }
}

.p-graduate__item-btn {
  position: absolute;
  right: 8px;
  bottom: 5px;
  z-index: 1;
  background-color: var(--color-orange);
  padding: 4px 5px;
  color: #fff;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1;
  letter-spacing: 0.08em;
}
@media (min-width: 48em) {
  .p-graduate__item-btn {
    font-size: 1.1rem;
  }
}
.p-graduate__item-btn span {
  vertical-align: text-bottom;
  margin-left: 1em;
}

.p-graduate__btn {
  display: block;
  position: relative;
  margin: 6.9841269841% auto 0;
  border: 1px solid var(--color-orange);
  padding: 2px 0;
  width: 33.3333333333%;
  color: var(--color-orange);
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 48em) {
  .p-graduate__btn {
    transition: opacity 0.3s;
    font-size: 1.6rem;
  }
}
.p-graduate__btn::before {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  background-color: var(--color-orange);
  width: 20px;
  height: 1px;
  content: "";
}
@media (min-width: 48em) {
  .p-graduate__btn:hover {
    opacity: 0.7;
  }
}

.p-life__container {
  position: relative;
  z-index: 1;
  padding: 5.7333333333% 10% 7.6%;
}
.p-life__container::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 0 20px 20px 0/0 20px 20px 0;
  background-image: url("/assets/images/pages/index/bg_blue.jpg");
  background-size: 100%;
  width: 94.6666666667%;
  height: 100%;
  content: "";
}

.p-life__heading {
  margin: 0 auto;
  width: 72%;
}

.p-life__btns {
  margin-top: 9%;
}

.p-life__btn {
  display: block;
}
@media (min-width: 48em) {
  .p-life__btn {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-life__btn:hover {
    opacity: 0.7;
  }
}
.p-life__btn + .p-life__btn {
  margin-top: 3.6666666667%;
}

.p-movie__container {
  padding: 3.8666666667% 0 0;
}

.p-movie__heading {
  margin: 0 auto;
  width: 47.7333333333%;
}

.p-movie__slider {
  margin-top: 3.3333333333%;
}

@media (min-width: 48em) {
  .p-movie__slide {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-movie__slide:hover {
    opacity: 0.7;
  }
}

.p-movie__arrow {
  opacity: 1;
  background-color: #df5722;
  width: 18px;
  height: 18px;
}
.p-movie__arrow svg {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.p-movie__dialog .c-dialog__document {
  padding: 0;
}
.p-movie__dialog #js-movie-inner {
  aspect-ratio: 16/9;
}

.p-mv {
  background-image: url("/assets/images/pages/index/bg_blue.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
  padding: 2.5333333333% 0 0;
  overflow: hidden;
}

.p-mv__bodies {
  display: flex;
  width: 200%;
}

.p-mv__body {
  flex-shrink: 0;
  animation: loop 50s -25s linear infinite;
  padding: 0 1.6333333333%;
  width: 50%;
}
.p-mv__body:nth-child(2) {
  animation: loop2 50s linear infinite;
}

.p-mv__heading {
  margin: 8.2666666667% auto 0;
  width: 80.8%;
}

.p-mv__img {
  margin-top: 4%;
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
.p-recommend {
  background-image: url("/assets/images/pages/index/bg_grid.jpg");
  background-size: 100%;
  background-repeat: repeat-y;
}

.p-recommend__container {
  padding: 11.8666666667% 9.3333333333% 5.7333333333%;
}

.p-recommend__heading {
  margin: 0 auto;
  width: 81.8032786885%;
}

.p-recommend__list {
  margin-top: 5.4098360656%;
}

.p-recommend__bnr {
  display: block;
  position: relative;
  margin-bottom: 6.0655737705%;
  border-radius: 10px;
  overflow: hidden;
}
@media (min-width: 48em) {
  .p-recommend__bnr {
    transition: opacity 0.3s;
  }
}
.p-recommend__bnr::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 2px solid var(--color-blue);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
}
@media (min-width: 48em) {
  .p-recommend__bnr:hover {
    opacity: 0.7;
  }
}

.p-recommend__bnrs {
  display: flex;
  justify-content: space-between;
}
.p-recommend__bnrs .p-recommend__bnr {
  border-radius: 5px;
  width: 47.5409836066%;
}
.p-recommend__bnrs .p-recommend__bnr::before {
  border-radius: 5px;
}

.p-social {
  margin-top: 16.2666666667%;
}

.p-social__container {
  padding: 0 9.3333333333% 10.6666666667%;
}

.p-social__heading {
  margin: 0 auto;
  width: 75.4098360656%;
}

.p-social__links {
  display: flex;
  justify-content: center;
  margin-top: 4.5901639344%;
}

.p-social__link {
  display: block;
  margin: 0 4.5901639344%;
  width: 8.5245901639%;
}
@media (min-width: 48em) {
  .p-social__link {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-social__link:hover {
    opacity: 0.7;
  }
}

.p-social__insta {
  display: block;
  margin: 13.9344262295% -11.4754098361% 0;
  width: 122.9508196721%;
  line-height: 0;
}
@media (min-width: 48em) {
  .p-social__insta {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-social__insta:hover {
    opacity: 0.7;
  }
}

.p-social__tw {
  margin-top: 15.0819672131%;
  font-size: 1.4rem;
}

.p-social__line {
  display: block;
  margin-top: 12.2950819672%;
}
@media (min-width: 48em) {
  .p-social__line {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-social__line:hover {
    opacity: 0.7;
  }
}

.p-social__mypage {
  display: block;
  margin-top: 5.9016393443%;
  border-radius: 10px;
  background-color: #fff;
}
@media (min-width: 48em) {
  .p-social__mypage {
    transition: opacity 0.3s;
  }
}
@media (min-width: 48em) {
  .p-social__mypage:hover {
    opacity: 0.7;
  }
}