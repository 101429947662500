// stylelint-disable selector-max-id
@use '../../abstracts/' as a;

.p-movie {
	//
}

.p-movie__container {
	padding: calc(29 / 750 * 100%) 0 0;
}

.p-movie__heading {
	margin: 0 auto;
	width: calc(358 / 750 * 100%);
}

.p-movie__slider {
	margin-top: calc(25 / 750 * 100%);
}

.p-movie__slider-track {
	//
}

.p-movie__slide {
	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-movie__arrow {
	opacity: 1;
	background-color: #df5722;
	width: 18px;
	height: 18px;

	svg {
		fill: #fff;
		width: 15px;
		height: 15px;
	}
}

.p-movie__dialog {
	.c-dialog__document {
		padding: 0;
	}

	#js-movie-inner {
		aspect-ratio: 16/9;
	}
}
