@use '../../abstracts/' as a;

.p-social {
	margin-top: calc(122 / 750 * 100%);
}

.p-social__container {
	padding: 0 calc(70 / 750 * 100%) calc(80 / 750 * 100%);
}

.p-social__heading {
	margin: 0 auto;
	width: calc(460 / 610 * 100%);
}

.p-social__links {
	display: flex;
	justify-content: center;
	margin-top: calc(28 / 610 * 100%);
}

.p-social__link {
	display: block;
	margin: 0 calc(28 / 610 * 100%);
	width: calc(52 / 610 * 100%);

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-social__insta {
	display: block;
	margin: calc(85 / 610 * 100%) calc(-70 / 610 * 100%) 0;
	width: calc(750 / 610 * 100%);
	line-height: 0;

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-social__tw {
	margin-top: calc(92 / 610 * 100%);
	font-size: 1.4rem;
}

.p-social__line {
	display: block;
	margin-top: calc(75 / 610 * 100%);

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-social__mypage {
	display: block;
	margin-top: calc(36 / 610 * 100%);
	border-radius: 10px;
	background-color: #fff;

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}
