@use '../../abstracts/' as a;

.p-life {
	//
}

.p-life__container {
	position: relative;
	z-index: 1;
	padding: calc(43 / 750 * 100%) calc(75 / 750 * 100%) calc(57 / 750 * 100%);

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 0 20px 20px 0 / 0 20px 20px 0;
		background-image: url('/assets/images/pages/index/bg_blue.jpg');
		background-size: 100%;
		width: calc(710 / 750 * 100%);
		height: 100%;
		content: '';
	}
}

.p-life__heading {
	margin: 0 auto;
	width: calc(432 / 600 * 100%);
}

.p-life__btns {
	margin-top: calc(54 / 600 * 100%);
}

.p-life__btn {
	display: block;

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}

	& + & {
		margin-top: calc(22 / 600 * 100%);
	}
}
