@use '../../abstracts/' as a;

.p-department {
	background-image: url('/assets/images/pages/index/bg_grid.jpg');
	background-size: 100%;
	background-repeat: repeat-y;
}

.p-department__container {
	padding: calc(96 / 750 * 100%) calc(70 / 750 * 100%) calc(62 / 750 * 100%);
}

.p-department__heading {
	margin: 0 auto;
	width: calc(358 / 610 * 100%);
}

.p-department__body {
	margin-top: calc(15 / 610 * 100%);
	font-size: 1.35rem;
	line-height: calc(41 / 27);
	letter-spacing: -0.05em;
	text-align: center;

	@include a.mq(md) {
		font-size: 2.3rem;
	}
}

.p-department__btns {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: calc(28 / 610 * 100%);
}

.p-department__btn {
	display: block;
	margin-bottom: calc(28 / 610 * 100%);
	width: calc(283 / 610 * 100%);

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:first-child,
	&:nth-child(6) {
		width: 100%;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}
