@use '../../abstracts/' as a;

.p-about {
	position: relative;
	z-index: 1;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		margin-top: calc(-18 / 750 * 100%);
		background-image: url('/assets/images/pages/index/about_bg.png');
		background-size: 100%;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		content: '';
	}
}

.p-about__container {
	padding: calc(57 / 750 * 100%) 0 0;
}

.p-about__heading {
	margin: 0 auto;
	width: calc(533 / 750 * 100%);
}

.p-about__body {
	margin-top: calc(20 / 750 * 100%);
	color: #fff;
	font-size: 1.35rem;
	line-height: calc(41 / 27);
	letter-spacing: 0.09em;
	text-align: center;

	@include a.mq(md) {
		font-size: 2.3rem;
	}
}

.p-about__bg {
	position: relative;
	margin-top: calc(30 / 750 * 100%);
}

.p-about__btns {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	justify-content: space-between;
	z-index: 1;
	padding: calc(36 / 750 * 100%) calc(70 / 750 * 100%);

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		content: '';
	}
}

.p-about__btn {
	display: block;
	width: calc(289 / 610 * 100%);

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}
