@use '../../abstracts/' as a;

.p-graduate {
	//
}

.p-graduate__container {
	padding: calc(63 / 750 * 100%) calc(60 / 750 * 100%) calc(58 / 750 * 100%);
}

.p-graduate__heading {
	margin: 0 auto;
	width: calc(499 / 630 * 100%);
}

.p-graduate__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: calc(45 / 730 * 100%);
}

.p-graduate__item {
	display: block;
	position: relative;
	margin-bottom: calc(14 / 630 * 100%);
	border-radius: 5px;
	width: calc(308 / 630 * 100%);
	overflow: hidden;

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}

.p-graduate__item-btn {
	position: absolute;
	right: 8px;
	bottom: 5px;
	z-index: 1;
	background-color: var(--color-orange);
	padding: 4px 5px;
	color: #fff;
	font-weight: 700;
	font-size: 0.7rem;
	line-height: 1;
	letter-spacing: 0.08em;

	@include a.mq(md) {
		font-size: 1.1rem;
	}

	span {
		vertical-align: text-bottom;
		margin-left: 1em;
	}
}

.p-graduate__btn {
	display: block;
	position: relative;
	margin: calc(44 / 630 * 100%) auto 0;
	border: 1px solid var(--color-orange);
	padding: 2px 0;
	width: calc(210 / 630 * 100%);
	color: var(--color-orange);
	font-size: 1rem;
	text-align: center;

	@include a.mq(md) {
		transition: opacity 0.3s;
		font-size: 1.6rem;
	}

	&::before {
		position: absolute;
		top: 50%;
		right: -10px;
		transform: translateY(-50%);
		background-color: var(--color-orange);
		width: 20px;
		height: 1px;
		content: '';
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}
