@use '../../abstracts/' as a;

.p-exam {
	//
}

.p-exam__container {
	padding: calc(76 / 750 * 100%) calc(70 / 750 * 100%) calc(40 / 750 * 100%);
}

.p-exam__heading {
	margin: 0 auto;
	width: calc(369 / 610 * 100%);
}

.p-exam__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: calc(34 / 610 * 100%);
}

.p-exam__btn {
	display: block;
	margin-bottom: calc(30 / 600 * 100%);
	width: calc(288 / 600 * 100%);

	@include a.mq(md) {
		transition: opacity 0.3s;
	}

	&:first-child {
		width: 100%;
	}

	&:hover {
		@include a.mq(md) {
			opacity: 0.7;
		}
	}
}
