@use '../../abstracts/' as a;

.p-bg {
	background-position: top center;
	background-size: 100%;
	background-repeat: repeat-y;
}

.p-bg--grid {
	background-image: url('/assets/images/pages/index/bg_grid.jpg');
}
